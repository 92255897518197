import { Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { SubheaderIcon } from '../components/SubheaderIcon'
import { PopularRoute } from './PopularRoute'
import { SummaryTransRecord } from './SummaryTransRecord'

export const ProfileTransportSummary = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { transportProfile } = profileStore

  const summary = useMemo(() => transportProfile?.summary, [transportProfile])

  return (
    <Stack my={2}>
      {summary?.top_auto_parking_location?.camera_address ||
      (summary?.top_auto_parking_location?.location_lat &&
        summary?.top_auto_parking_location?.location_lon) ? (
        <Stack>
          <SummaryTransRecord
            title='parking'
            blackKey
            name={
              summary?.top_auto_parking_location?.camera_address ||
              `${summary?.top_auto_parking_location?.location_lat} - ${summary?.top_auto_parking_location?.location_lon}`
            }
            description={summary?.top_auto_parking_location?.parking_count}
          />
        </Stack>
      ) : null}

      {summary?.top_auto_route?.start_camera_address ||
      summary?.top_auto_route?.end_camera_address ? (
        <PopularRoute
          title={
            <Stack flexDirection='row' alignItems='center'>
              <SubheaderIcon name='TrackChangesIcon' warningType />

              <Typography
                textAlign='left'
                variant='h6'
                color='common.black'
                sx={{ ml: 1 }}
              >
                Наиболее популярный маршрут поездки
              </Typography>
            </Stack>
          }
          from={summary?.top_auto_route?.start_camera_address}
          to={summary?.top_auto_route?.end_camera_address}
        />
      ) : null}

      {summary?.top_auto_route?.total_trip_count ? (
        <SummaryTransRecord
          blackKey
          name='Количество поездок:'
          description={summary?.top_auto_route?.total_trip_count}
        />
      ) : null}

      {summary?.top_districts?.length ? (
        <Stack>
          <SummaryTransRecord title='districts' blackKey />

          {summary?.top_districts?.map(
            ({ district, cnt }: any, idx: number) => (
              <SummaryTransRecord
                key={idx}
                name={district}
                blackKey
                description={cnt}
              />
            )
          )}
        </Stack>
      ) : null}
    </Stack>
  )
})
