import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import { request } from 'utils/request'
import * as S from './FaceMatching.styled'
import { v4 } from 'uuid'
import { FotoStrana, Teamo, LovePlanet, Tg, Vk } from 'store/faceMatchingStore/types'
import { getTgDate, renderVal, renderVkVal, translateKey } from './utils'
import { Image as AntdImage } from 'antd'

export const Image = React.memo(function Image({ src }: { src: string }) {
  return (
    <AntdImage
      style={{
        cursor: 'pointer',
        height: 150,
        width: 150,
        borderRadius: '12px 0 0 12px',
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      }}
      preview={{ mask: false }}
      src={src}
    />
  )
})

export const Data = observer(({ url }: { url: string }) => {
  const [lovePlanet, setLovePlanet] = useState<null | LovePlanet>(null)
  const [fotoStrana, setFotoStrana] = useState<null | FotoStrana>(null)
  const [teamo, setTeamo] = useState<null | Teamo>(null)
  const [tg, setTg] = useState<null | Tg>(null)
  const [vk, setVk] = useState<null | Vk>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDataRequested, setIsDataRequested] = useState(false)

  const emptyState = useMemo(
    () => !tg && !fotoStrana && !lovePlanet && !vk && !teamo,
    [fotoStrana, lovePlanet, tg, vk, teamo]
  )

  const handleClick = useCallback(
    async (url: string) => {
      if (url && !isDataRequested) {
        setIsLoading(true)
        const res = await request(`${url}?download=0`)

        const { payload } = res

        if (payload) {
          if (payload?.system_name === 3) {
            if (payload?.vk_id) {
              setVk({ meta_data: payload?.meta_data, vk_id: payload.vk_id })
            }
          } else if (payload?.system_name === 6) {
            if (
              payload?.tags?.some((i: any) => i?.toLowerCase() === 'fotostrana')
            ) {
              setFotoStrana({ ...payload?.meta_data })
            } else if (
              payload?.tags?.some((i: any) => i?.toLowerCase() === 'loveplanet')
            ) {
              setLovePlanet({ ...payload?.meta_data })
            } else if (
              payload?.tags?.some((i: any) => i?.toLowerCase() === 'teamo')
            ) {
              setTeamo({ ...payload?.meta_data })
            }
          } else if (payload?.system_name === 1) {
            //tg
            const tgRes = await request(
              // `http://37.77.107.234:7777/users?user_id=${payload.tg_id}`
              `https://mfindsomething.ru/radar/users?user_id=${payload.tg_id}`
            )

            if (tgRes?.payload?.users?.length) {
              setTg(tgRes.payload.users[0])
            }
          }
        }

        setIsLoading(false)
        setIsDataRequested(true)
      }
    },
    [isDataRequested]
  )

  return (
    <Stack
      ml={2}
      width={1}
      spacing={1}
      alignItems='flex-start'
      justifyContent='center'
    >
      {emptyState && (
        <Button
          sx={{ textTransform: 'none', color: '#40a9ff' }}
          onClick={() => handleClick(url)}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: '#40a9ff' }} />
          ) : (
            'Просмотреть'
          )}
        </Button>
      )}

      {!emptyState && (
        <>
          {vk && (
            <S.MordovorotCard spacing={1}>
              {vk?.vk_id ? (
                <Typography variant='body2'>
                  <a
                    href={`https://vk.com/id${vk.vk_id}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Профиль
                  </a>
                </Typography>
              ) : null}

              {vk?.meta_data && Object.keys(vk.meta_data)?.length ? (
                <>
                  {Object.entries(vk.meta_data)
                    .filter(
                      ([k]) =>
                        k !== 'album_id' &&
                        k !== 'photo_id' &&
                        k !== 'post_id' &&
                        k !== 'web_view_token'
                    )
                    .map(([key, val]) => (
                      <Typography key={v4()} variant='body2'>
                        {translateKey(key)}:{' '}
                        {val ? renderVkVal(key, val) : '---'}
                      </Typography>
                    ))}
                </>
              ) : null}
            </S.MordovorotCard>
          )}

          {fotoStrana && (
            <S.MordovorotCard spacing={1}>
              {fotoStrana?.url ? (
                <Typography variant='body2'>
                  <a href={fotoStrana.url} target='_blank' rel='noreferrer'>
                    Профиль
                  </a>
                </Typography>
              ) : null}

              {fotoStrana?.name ? (
                <Typography key={v4()} variant='body2'>
                  Имя: {fotoStrana.name}
                </Typography>
              ) : null}

              {fotoStrana?.birthdate ? (
                <Typography key={v4()} variant='body2'>
                  День рождения: {fotoStrana.birthdate}
                </Typography>
              ) : null}

              {fotoStrana?.address ? (
                <Typography key={v4()} variant='body2'>
                  Адрес: {fotoStrana.address}
                </Typography>
              ) : null}

              {fotoStrana?.description ? (
                <Typography key={v4()} variant='body2'>
                  Описание: {fotoStrana.description}
                </Typography>
              ) : null}
            </S.MordovorotCard>
          )}

          {lovePlanet && (
            <S.MordovorotCard spacing={1}>
              {lovePlanet?.url ? (
                <Typography variant='body2'>
                  <a href={lovePlanet.url} target='_blank' rel='noreferrer'>
                    Профиль
                  </a>
                </Typography>
              ) : null}

              {lovePlanet?.name ? (
                <Typography key={v4()} variant='body2'>
                  Имя: {lovePlanet.name}
                </Typography>
              ) : null}
              {lovePlanet?.age ? (
                <Typography key={v4()} variant='body2'>
                  Возраст: {lovePlanet.age}
                </Typography>
              ) : null}
              {lovePlanet?.city ? (
                <Typography key={v4()} variant='body2'>
                  Город: {lovePlanet.city}
                </Typography>
              ) : null}
              {lovePlanet?.status ? (
                <Typography key={v4()} variant='body2'>
                  Статус: {lovePlanet.status}
                </Typography>
              ) : null}
              {lovePlanet?.search_for ? (
                <Typography key={v4()} variant='body2'>
                  Поиск: {lovePlanet.search_for}
                </Typography>
              ) : null}
              {lovePlanet?.zodiac_sign ? (
                <Typography key={v4()} variant='body2'>
                  Зодиак: {lovePlanet.zodiac_sign}
                </Typography>
              ) : null}

              {lovePlanet?.info && Object.keys(lovePlanet.info)?.length ? (
                <>
                  {Object.entries(lovePlanet.info).map(([key, val]) => (
                    <Typography key={v4()} variant='body2'>
                      {key}: {val ? renderVal(val) : '---'}
                    </Typography>
                  ))}
                </>
              ) : null}
            </S.MordovorotCard>
          )}

          {teamo && (
            <S.MordovorotCard spacing={1}>
              {teamo?.url ? (
                <Typography variant='body2'>
                  <a href={teamo.url} target='_blank' rel='noreferrer'>
                    Профиль
                  </a>
                </Typography>
              ) : null}

              {teamo?.Имя ? (
                <Typography key={v4()} variant='body2'>
                  Имя: {teamo.Имя}
                </Typography>
              ) : null}

              {teamo?.Город ? (
                <Typography key={v4()} variant='body2'>
                  Город: {teamo.Город}
                </Typography>
              ) : null}

              {teamo?.Статус ? (
                <Typography key={v4()} variant='body2'>
                  Статус: {teamo.Статус}
                </Typography>
              ) : null}

              {teamo?.['Знак зодиака'] ? (
                <Typography key={v4()} variant='body2'>
                  Знак зодиака: {teamo['Знак зодиака']}
                </Typography>
              ) : null}

              {teamo?.['Личная информация'] && Object.values(teamo['Личная информация'])?.length ? <>
                <Divider sx={{ my: '8px !important' }} />

                {Object.entries(teamo['Личная информация']).filter(([_, v]) => v).map(([key, value]) => (
                  <Typography key={v4()} variant='body2' display='flex' flexDirection='column'>
                    <Box component='span' color='text.secondary'>{key}:</Box>
                    <Box component='span'>{value}</Box>
                  </Typography>
                ))}
              </> : null}

              {teamo?.['Отношение к жизни'] && Object.values(teamo['Отношение к жизни'])?.length ? <>
                <Divider sx={{ my: '8px !important' }} />

                {Object.entries(teamo['Отношение к жизни']).filter(([_, v]) => v).map(([key, value]) => (
                  <Typography key={v4()} variant='body2' display='flex' flexDirection='column'>
                    <Box component='span' color='text.secondary'>{key}:</Box>
                    <Box component='span'>{value}</Box>
                  </Typography>
                ))}
              </> : null}
            </S.MordovorotCard>
          )}

          {tg && (
            <S.MordovorotCard spacing={1}>
              {tg?.username ? (
                <Typography variant='body2'>
                  <a
                    href={`https://t.me/${tg.username}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Профиль
                  </a>
                </Typography>
              ) : null}

              {tg?.user_id ? (
                <Typography key={v4()} variant='body2'>
                  ID: {tg.user_id}
                </Typography>
              ) : null}

              {tg?.first_name ? (
                <Typography key={v4()} variant='body2'>
                  Имя: {tg.first_name}
                </Typography>
              ) : null}

              {tg?.last_name ? (
                <Typography key={v4()} variant='body2'>
                  Фамилия: {tg.last_name}
                </Typography>
              ) : null}

              {tg?.phone ? (
                <Typography key={v4()} variant='body2'>
                  Телефон: {tg.phone}
                </Typography>
              ) : null}

              {tg?.online ? (
                <Typography key={v4()} variant='body2'>
                  В сети: {getTgDate(tg.online)}
                </Typography>
              ) : null}
            </S.MordovorotCard>
          )}
        </>
      )}
    </Stack>
  )
})
