import { Box, Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useContext, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { SubheaderIcon } from '../components/SubheaderIcon'

const InfoRecord = ({ name, value }: { name: string; value: any }) => {
  return (
    <Stack
      justifyContent='space-between'
      sx={{
        mt: 0.5,
        width: '100%',
        maxWidth: '900px',
      }}
    >
      <Stack
        sx={{ width: '100%' }}
        direction='row'
        justifyContent='space-between'
      >
        <Typography
          width='50%'
          textAlign='start'
          color='common.black'
          variant='body1'
          sx={{ wordBreak: 'break-word' }}
        >
          {name}
        </Typography>
        <Typography
          width='50%'
          color='text.disabled'
          variant='body1'
          textAlign='end'
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  )
}

export const AdditionalAddresses = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { additionalInfo } = profileStore

  const { efor_addresses } = additionalInfo

  const [expanded, setExpanded] = useState<string | false>(false)
  const [expandedInner, setExpandedInner] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const handleChangeInner =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpandedInner(newExpanded ? panel : false)
    }

  if (!efor_addresses) {
    return null
  }

  const { request_value, results } = efor_addresses

  return (
    <Box sx={{ px: 2, maxWidth: '900px', width: '100%', mt: 2 }}>
      <Accordion
        sx={{ width: '100%' }}
        expanded={expanded === 'additionalAddresses'}
        onChange={handleChange('additionalAddresses')}
      >
        <AccordionSummary>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='RocketLaunchOutlinedIcon' />
            </Box>

            <Typography variant='body1' color='common.black'>
              Адреса по {request_value}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {results.map(
            (
              { address, mark_source, mark_target, phone }: any,
              idx: number
            ) => (
              <Accordion
                key={Math.random()}
                sx={{ width: '100%' }}
                expanded={expandedInner === `inner-${idx}`}
                onChange={handleChangeInner(`inner-${idx}`)}
              >
                <AccordionSummary>
                  <Stack direction='row'>
                    <Box
                      sx={{
                        mr: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <SubheaderIcon name='RocketLaunchOutlinedIcon' />
                    </Box>

                    <Typography variant='body1' color='common.black'>
                      Адреса по {phone}
                    </Typography>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Stack>
                    <InfoRecord name='Адрес' value={address} />
                    <InfoRecord name='Источник' value={mark_source} />
                    <InfoRecord name='Таргет' value={mark_target} />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
})
