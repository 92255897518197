import { Stack, styled } from '@mui/material'

export const ChartContainer = styled(Stack)<{ count: number }>(({ theme }) => ({
  margin: '24px auto',
  width: 'calc(50% - 16px)', // Равномерное распределение по ширине
  height: '400px', // Задаем фиксированную высоту
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))
